import React, { useState } from "react"
import styled, { css } from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

import CategoryItems from "./CategoryItems"

const Header = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  text-transform: uppercase;
  justify-content: center;
  align-items: center;
`

const ClearButton = styled.button`
  width: 100%;
  height: 50px;
  position: relative;
  font-size: 14px;
  appearance: none;
  border-radius: 13px;
  color: #707070;
  background: #c5ccce55;
  box-shadow: 0px 3px 6px #00000029;
  cursor: pointer;
  outline: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;

  :disabled {
    color: #707070;
    opacity: 0.8;
  }
`

const CategoryList = ({ title, items, sorter }) => {
  const [open, setOpen] = useState(false)
  const [animating, setAnimating] = useState(false)

  const container = {
    hidden: { opacity: 0, height: 0 },
    show: {
      opacity: 1,
      height: "auto",
      transition: {
        staggerChildren: 0.08,
        // staggerChildren: 0.3,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, y: -20 },
    show: { opacity: 1, y: 0 },
  }

  const handleOpen = () => {
    setOpen(prev => !prev)
    setAnimating(true)
  }

  const handleAnimationExit = () => {
    setAnimating(false)
  }

  return (
    <div
      css={css`
        margin-bottom: 20px;
        background: #c5ccce55;
        border-radius: 13px;
      `}
    >
      <ClearButton disabled={animating} onClick={handleOpen}>
        <Header expanded={open}>{title}</Header>
      </ClearButton>
      <AnimatePresence exitBeforeEnter onExitComplete={handleAnimationExit}>
        {open && (
          <motion.div
            initial="hidden"
            animate="show"
            exit={{ transition: { duration: 0.01 } }}
            onAnimationComplete={handleAnimationExit}
          >
            <CategoryItems
              items={items}
              containerVariants={container}
              variants={item}
              sorter={sorter}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
export default CategoryList
