import React from "react"
import { graphql } from "gatsby"

import ContentLayout from "../components/ContentLayout"
import CategoryList from "../components/CategoryList"

import Icon from "../svg/menu/szakleiras.svg"

const SzakleirasPage = ({
  data: {
    bsc: { nodes: bsc },
    msc: { nodes: msc },
    foksz: { nodes: foksz },
    cover: { childImageSharp: cover },
  },
}) => (
  <ContentLayout icon={Icon} title="Szakleírás" cover={cover.fluid}>
    {[
      { title: "Alapképzési és osztatlan szakok (BSc/BA)", majors: bsc },
      { title: "Mesterképzési szakok (MSc/MA)", majors: msc },
      { title: "Felsőoktatási szakképzési szakok", majors: foksz },
    ].map(({ title, majors }) => (
      <CategoryList key={title} title={title} items={majors} />
    ))}
  </ContentLayout>
)

export default SzakleirasPage

export const query = graphql`
  query szakleirasok {
    bsc: allMdx(
      filter: {
        frontmatter: { degree: { eq: "bsc" } }
        slug: { regex: "/^(szakleiras)/.*$/" }
      }
    ) {
      nodes {
        id
        path: slug
        frontmatter {
          title
        }
      }
    }
    msc: allMdx(
      filter: {
        frontmatter: { degree: { eq: "msc" } }
        slug: { regex: "/^(szakleiras)/.*$/" }
      }
    ) {
      nodes {
        id
        path: slug
        frontmatter {
          title
        }
      }
    }
    foksz: allMdx(
      filter: {
        frontmatter: { degree: { eq: "foksz" } }
        slug: { regex: "/^(szakleiras)/.*$/" }
      }
    ) {
      nodes {
        id
        path: slug
        frontmatter {
          title
        }
      }
    }
    cover: file(relativePath: { eq: "covers/szakleiras.png" }) {
      childImageSharp {
        fluid(cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
