import React from "react"
import { Link } from "gatsby"
import { css } from "styled-components"
import { FiChevronRight } from "react-icons/fi"
import { motion } from "framer-motion"
import { useLocation } from "@reach/router"

import { titleSorter } from "../utils"

const CategoryItems = ({
  items,
  variants,
  containerVariants,
  sorter = titleSorter,
}) => {
  const location = useLocation()
  return (
    <div
      css={css`
        background: #c5ccce55;
        border-bottom-left-radius: 13px;
        border-bottom-right-radius: 13px;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 10px;
        position: relative;

        a:not(:last-child) {
          border-bottom: 1px solid white;
        }
      `}
    >
      <motion.div variants={containerVariants}>
        {items.sort(sorter).map(({ id, path, frontmatter: { title } }) => (
          <motion.div key={id} variants={variants}>
            <Link
              key={id}
              css={css`
                padding: 10px;
                display: flex;
                width: 100%;
                text-decoration: none;
                color: #707070;
                align-items: center;
                justify-content: space-between;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-touch-callout: none;
                outline: 0;
              `}
              to={`/${path}`}
              state={{ from: location.pathname }}
            >
              <div>{title}</div>
              <FiChevronRight size={20} />
            </Link>
          </motion.div>
        ))}
      </motion.div>
    </div>
  )
}

export default CategoryItems
